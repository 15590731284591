import React from 'react';
import { Link } from 'react-router-dom';

// Forms
import Newsletter from 'components/parts/newsletter';

// Parts
import Contact from 'components/parts/footer/contact';
import MenuCustom from 'components/parts/menucustom';
import MainMenu from 'components/parts/menu';


const Footer = ({ page }) => {

  const links_offer = [
    {
      link: "/autoservis",
      text: "Autoservis"
    },
    {
      link: "/velkoobchod",
      text: "Velkoobchod"
    },
    {
      link: "/maloobchod",
      text: "Maloobchod"
    },
  ];
  const links_about = [
    {
      link: "/onas",
      text: "O nás"
    },
    {
      link: "/sortiment",
      text: "Sortiment"
    },
    {
      link: "/kariera",
      text: "Kariéra"
    },
  ];
  const links_shopping = [
    {
      link: "/doprava",
      text: "Doprava"
    },
    {
      link: "/reklamace",
      text: "Reklamace"
    },
    {
      link: "/vraceni",
      text: "Vrácení zboží"
    },
    {
      link: "/podminky",
      text: "Obchodní podmínky"
    },
    {
      link: "/dokumenty",
      text: "Dokumenty"
    },
  ];
  const links_navigation = [
  ];


  return(
    <div className="row-cols nobg nospace-x">

      <main className="col-middle noedge">
        {
          (page != "cart") &&
            <Newsletter />
        }

        <div id="footer">
          <Contact classes="inverted" />
          <div className="spacer"></div>
          <MenuCustom title="Nabízíme" links={links_offer} classes="inverted" />
          <MenuCustom title="O nás" links={links_about} classes="inverted" />
          <MenuCustom title="Nákup" links={links_shopping} classes="inverted" />
          <MainMenu title="Navigace" links={links_navigation} classes="inverted" />
        </div>

        <div>
          &#169; PRONDO {new Date().getFullYear()}, vytvořil <a href="https://zatloukal.net" target="_blank" className="link">Ing. Filip Zatloukal</a>
        </div>
      </main>

    </div>
  );

}

export default Footer;
