import React from 'react';

import AboutusPage from './simple/aboutusPage';
import CareerPage from './simple/careerPage';
import CarrierRentPage from './simple/carrierRentPage';
import CarservicePage from './simple/carservicePage';
import ComplaintPage from './simple/complaintPage';
import ContactPage from './simple/contactPage';
import DocumentsPage from './simple/documentsPage';
import GoodsPage from './simple/goodsPage';
import ProjectsPage from './simple/projectsPage';
import RetailPage from './simple/retailPage';
import ReturnPage from './simple/returnPage';
import ShippingPage from './simple/shippingPage';
import TermsPage from './simple/termsPage';
import WholesalePage from './simple/wholesalePage';


const SimplePage = ({ page, props }) => {

  const getPage = () => {
    switch (page) {
      case 'aboutus':
        return <AboutusPage />;
      case 'career':
        return <CareerPage />;
      case 'carrierrent':
        return <CarrierRentPage />;
      case 'carservice':
        return <CarservicePage />;
      case 'complaint':
        return <ComplaintPage />;
      case 'contact':
        return <ContactPage />;
      case 'documents':
        return <DocumentsPage />;
      case 'goods':
        return <GoodsPage />;
      case 'projects':
        return <ProjectsPage />;
      case 'retail':
        return <RetailPage />;
      case 'return':
        return <ReturnPage />;
      case 'shipping':
        return <ShippingPage />;
      case 'terms':
        return <TermsPage />;
      case 'wholesale':
        return <WholesalePage />;
      default:
        return null;
    }
  }

  return(
    <section id="simplepage" className="row-cols">
      <aside className="col-left">
      </aside>
      <main className="col-middle">
        {
           getPage()
        }
      </main>
      <aside className="col-right">
      </aside>
    </section>
  );

}

export default SimplePage;
